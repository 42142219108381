
























































import { Component, Vue, Prop } from "vue-property-decorator";
import Member from "../models/Member";
import MemberNode from "./MemberNode.vue";

@Component({
  name: "Node",
  components: {
    MemberNode,
  },
})
class Node extends Vue {
  @Prop()
  member!: Member;

  @Prop()
  isRoot!: boolean;

  @Prop()
  showAll!: boolean;

  @Prop()
  hideAll!: boolean;

  @Prop()
  disableShowHideAll!: Function;

  @Prop()
  openEditTreeModal!: Function;

  @Prop()
  closeEditTreeModal!: Function;

  @Prop()
  rerenderTree!: Function;

  hideChildren =
    (this.showAll &&
      this.$store.state.idsOfParentsOfChildrenToHide.has(this.member.id)) ||
    (this.hideAll &&
      !this.$store.state.idsOfParentsOfChildrenToShow.has(this.member.id));

  topBorderWidth = "0px";
  topBorderLeft = "0px";

  spouseConnectorBarWidth = "100px";
  spouseConnectorBarLeft = "100px";

  mounted() {
    this.calculateTopBarStyle();
    this.calculateSpouseConnectorBarWidth();
  }

  calculateSpouseConnectorBarWidth() {
    const spouseContainers = this.$el.querySelectorAll(
      ".spouse-container>.member-container"
    );
    if (spouseContainers.length > 0) {
      const memberRight = this.$el
        .querySelectorAll(".spouses-container>.member-container")[0]
        .getBoundingClientRect().right;

      const spouseContainerLeft = spouseContainers[0].getBoundingClientRect()
        .left;
      this.spouseConnectorBarWidth = spouseContainerLeft - memberRight + "px";
    }
  }

  calculateTopBarStyle() {
    // Refers to children nodes under current node
    const treeChildren = this.$el.querySelectorAll(
      `.${this.sanitizeString(
        this.member.name
      )}-child>.node-container>.node .member-container img`
    );

    // Only show a bar connecting children if more than one child
    if (treeChildren.length > 1) {
      const memberContainer = this.$el.querySelectorAll(
        `.${this.sanitizeString(this.member.name)}-child>.node-container>.node`
      )[0];
      const verticalBars = this.$el.querySelectorAll(
        `.${this.sanitizeString(
          this.member.name
        )}-child>.node-container>.node>.vertical-bar`
      );
      const firstVerticalBarLeft = verticalBars[0].getBoundingClientRect().left;
      const lastVerticalBarLeft = verticalBars[
        verticalBars.length - 1
      ].getBoundingClientRect().left;

      // Calculate width of top border, which serves as connection line between siblings
      // Calculate width by finding difference in position of last vertical bar and first vertical bar
      this.topBorderWidth = lastVerticalBarLeft - firstVerticalBarLeft + "px";

      // Calculate left position of top border by subtracting position of first vertical bar of children by container of children
      this.topBorderLeft =
        firstVerticalBarLeft -
        memberContainer.getBoundingClientRect().left +
        "px";
    }
  }

  sanitizeString(str: string) {
    return str.replace(/ /g, "_");
  }

  toggleHideChildren() {
    this.$store.commit("toggleHideChildren", this.member.id);
    this.rerenderTree();
  }
}
export default Node;
