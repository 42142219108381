import Vue from "vue";
import App from "./App.vue";
import Vuex from 'vuex';


const port = 3002;

// for personal machine development
// const serverAddress = `http://localhost:${port}`

// for deployment
const serverAddress = `https://hashmi.site:${port}`

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    serverAddress,
    idsOfParentsOfChildrenToHide: new Set(),
    idsOfParentsOfChildrenToShow: new Set()
  },
  mutations: {
    toggleHideChildren(state, idOfParent) {
      state.idsOfParentsOfChildrenToHide.has(idOfParent) ? state.idsOfParentsOfChildrenToHide.delete(idOfParent) : state.idsOfParentsOfChildrenToHide.add(idOfParent)
      state.idsOfParentsOfChildrenToShow.has(idOfParent) ? state.idsOfParentsOfChildrenToShow.delete(idOfParent) : state.idsOfParentsOfChildrenToShow.add(idOfParent)
    },
  }

})


Vue.config.productionTip = false;

new Vue({
  store: store,
  render: h => h(App)
}).$mount("#app");
