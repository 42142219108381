



































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Member from "../models/Member";

class ModalData {
  headingText: string;
  endpoint: string;
  requestMethod: string;

  constructor(headingText: string, endpoint: string, requestMethod: string) {
    this.headingText = headingText;
    this.endpoint = endpoint;
    this.requestMethod = requestMethod;
  }
}

@Component({
  name: "EditTreeModal",
})
export default class EditTreeModal extends Vue {
  @Prop()
  member!: Member;

  @Prop()
  option!: number;

  @Prop()
  closeEditTreeModal!: Function;

  modalData = [
    new ModalData(`Add parent for ${this.member.name}`, `/parent`, `POST`),
    new ModalData(`Add spouse for ${this.member.name}`, `/spouse`, `POST`),
    new ModalData(`Add child for ${this.member.name}`, `/child`, `POST`),
    new ModalData(`Rename ${this.member.name}`, `/edit`, `POST`),
    new ModalData(
      `Reorder children for ${this.member.name}`,
      `/reorder-children`,
      `PUT`
    ),
  ];

  errorMessage = "";

  name = null;
  selectedNameFromDropdown = "";
  disableSubmit = false;
  addingExistingUser = null;
  // ID of existing user being added again in tree
  existingMemberId = null;

  spouseNamesToIdMap: { [key: string]: number } = {};

  childrenNamesToSortDataMap: { [key: string]: { [key: string]: number } } = {};
  childrenNamesToSortDataMapDuplicate: {
    [key: string]: { [key: string]: number };
  } = {};

  // Only populated if adding existing member as another relationship
  allMembersInTreeToIdMap: { [key: string]: number } = {};

  modalDataToUse = this.modalData[this.option];

  @Watch("addingExistingUser")
  addingExistingMemberOptionHandler(value: boolean, oldValue: boolean) {
    if (value && Object.keys(this.allMembersInTreeToIdMap).length === 0) {
      fetch(`${this.$store.state.serverAddress}/existing-members`)
        .then((res) => res.json())
        .then((res) => {
          this.allMembersInTreeToIdMap = res;
        });
    }
    // Added to satisfy compiler
    oldValue;
  }

  mounted() {
    // If adding child, need to get dropdown values for parent 2
    if (this.option === 2) {
      fetch(
        `${this.$store.state.serverAddress}/spouses?memberId=${this.member.id}`
      )
        .then((res) => res.json())
        .then((res) => {
          this.spouseNamesToIdMap = res;
        });
    } else if (this.option === 4) {
      fetch(
        `${this.$store.state.serverAddress}/children?memberId=${this.member.id}`
      )
        .then((res) => res.json())
        .then((res) => {
          const childrenNamesToSortDataMap: {
            [key: string]: { [key: string]: number };
          } = {};
          Object.keys(res).forEach(
            (childName, i) =>
              (childrenNamesToSortDataMap[childName] = {
                orderNumber: i,
                childId: res[childName],
              })
          );
          this.childrenNamesToSortDataMap = { ...childrenNamesToSortDataMap };
          this.childrenNamesToSortDataMapDuplicate = {
            ...childrenNamesToSortDataMap,
          };
        });
    }
  }

  disableSubmitButton() {
    if (this.option === 2 && !this.selectedNameFromDropdown) return true;
    else if (this.option === 4) return false;
    else if (!this.name && !this.existingMemberId) return true;

    return false;
  }

  getAddParentRequestBody() {
    return {
      memberId: this.member.id,
      parentName: this.name,
      parentIsExistingMember: this.addingExistingUser,
      existingMemberId: this.existingMemberId,
    };
  }

  getAddSpouseRequestBody() {
    return {
      memberId: this.member.id,
      spouseName: this.name,
      spouseIsExistingMember: this.addingExistingUser,
      existingMemberId: this.existingMemberId,
    };
  }

  getAddChildRequestBody() {
    return {
      parent1Id: this.member.id,
      parent2Id: this.spouseNamesToIdMap[this.selectedNameFromDropdown],
      childName: this.name,
      childIsExistingMember: this.addingExistingUser,
      existingMemberId: this.existingMemberId,
    };
  }

  getEditMemberRequestBody() {
    return {
      memberId: this.member.id,
      newName: this.name,
    };
  }

  getReorderChildrenRequestBody() {
    const sortedChildrenNames = Object.keys(
      this.childrenNamesToSortDataMapDuplicate
    ).sort((a, b) =>
      this.childrenNamesToSortDataMapDuplicate[a].orderNumber >
      this.childrenNamesToSortDataMapDuplicate[b].orderNumber
        ? 1
        : -1
    );
    return {
      memberId: this.member.id,
      reorderedChildren: sortedChildrenNames.map(
        (name) => this.childrenNamesToSortDataMapDuplicate[name].childId
      ),
    };
  }

  getRequestBody() {
    switch (this.option) {
      case 0:
        return this.getAddParentRequestBody();

      case 1:
        return this.getAddSpouseRequestBody();

      case 2:
        return this.getAddChildRequestBody();

      case 3:
        return this.getEditMemberRequestBody();

      case 4:
        return this.getReorderChildrenRequestBody();
    }
  }

  updateChildOrder(event: Event, childName: string) {
    // Without casting event.target as HTMLSelectElement, the `value` property does not exist
    const newPosition = parseInt((event.target as HTMLSelectElement).value);
    this.childrenNamesToSortDataMapDuplicate[
      childName
    ].orderNumber = newPosition;
  }

  verifyReorderedValues() {
    const orderNumberSet = new Set();
    // Check for duplicates
    // if duplicates exist, order is invalid
    for (const sortData of Object.values(
      this.childrenNamesToSortDataMapDuplicate
    )) {
      if (orderNumberSet.has(sortData.orderNumber)) return false;
      orderNumberSet.add(sortData.orderNumber);
    }

    // If no duplicates exist, order is valid
    return true;
  }

  handleSubmit() {
    if (this.option === 4) {
      if (!this.verifyReorderedValues()) {
        this.errorMessage =
          "One or more order values is invalid, please verify there are no duplicates";
        return;
      } else {
        this.errorMessage = "";
      }
    }
    const options = {
      method: this.modalData[this.option].requestMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.getRequestBody()),
    };
    fetch(
      this.$store.state.serverAddress + this.modalData[this.option].endpoint,
      options
    ).then(() => location.reload());
    this.closeEditTreeModal();
  }
}
