














































import { Component, Vue, Prop } from "vue-property-decorator";
import Member from "../models/Member";

@Component({
  name: "MemberNode",
})
export default class MemberNode extends Vue {
  @Prop()
  member!: Member;

  @Prop()
  openEditTreeModal!: Function;

  @Prop()
  isSpouse!: boolean;

  @Prop()
  toggleHideChildren!: Function;

  deleteUser() {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        memberId: this.member.id,
      }),
    };
    fetch(this.$store.state.serverAddress + "/delete", options).then(() =>
      location.reload()
    );
  }
}
