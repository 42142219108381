
























import Node from "./Node.vue";
import EditTreeModal from "./EditTreeModal.vue";
import { Component, Vue } from "vue-property-decorator";
import Member from "../models/Member";

@Component({
  name: "Home",
  components: { Node, EditTreeModal },
})
export default class Home extends Vue {
  showAll = true;
  hideAll = false;

  receivedData = false;
  rootMember!: Member;
  displayModal = false;

  modalOption?: number;
  modalMember?: Member;

  key = 0;

  mounted() {
    fetch(`${this.$store.state.serverAddress}/family-tree`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.rootMember = res;
        this.receivedData = true;
      })
      .catch((e) => {
        console.log("Ran into error");
        console.log(e);
      });
  }

  toggleShowHideAll() {
    this.showAll = !this.showAll;
    this.hideAll = !this.showAll;
    if (this.showAll) {
      this.$store.state.idsOfParentsOfChildrenToHide.clear();
      this.$store.state.idsOfParentsOfChildrenToShow.clear();
    }
    this.rerenderTree();
  }

  rerenderTree() {
    // incrementing key in order to re-render entire tree
    this.key++;
  }

  openEditTreeModal(option: number, member: Member) {
    this.modalOption = option;
    this.modalMember = member;
    this.displayModal = true;
  }

  closeEditTreeModal() {
    this.displayModal = false;
  }
}
